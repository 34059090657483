import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightWord',
  standalone: true
})
export class HighlightWordPipe implements PipeTransform {
  transform(value: string, args: string, type: string): unknown {
    if (!args) return value;
    const escapedArgs = args.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    if (type === 'full') {
      const re = new RegExp('\\b(' + escapedArgs + '\\b)', 'igm');
      value = value.replace(re, '<span class="highlighted-text">$1</span>');
    } else {
      const re = new RegExp(escapedArgs, 'igm');
      value = value.replace(re, '<span class="highlighted-text">$&</span>');
    }

    return value;
  }
}