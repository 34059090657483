import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appUppercaseWord]',
  standalone: true,
})
export class UppercaseWordDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const transformedValue = input.value.toUpperCase();
    this.renderer.setProperty(input, 'value', transformedValue);
  }
}
